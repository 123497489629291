.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  min-height: 80vh;
  background: linear-gradient(135deg, #1345CA 0%, #0B0D17 100%);
  background-image: linear-gradient(135deg, #1345CA 0%, #0B0D17 100%),
    radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, transparent 100%);
  background-blend-mode: overlay;
}

.subscriptionTitle {
  font-size: 3rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-bottom: 0.8rem;
  text-align: center;
  text-shadow: 0 2px 15px rgba(255,255,255,0.2);
  letter-spacing: 0.5px;
}

.subscriptionDescription {
  font-size: 1.2rem;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .subscriptionTitle {
    font-size: 2.5rem;
    margin-bottom: 1.2rem;
  }
  
  .subscriptionDescription {
    margin-top: 1.5rem;
  }
}

@media (max-width: 480px) {
  .subscriptionTitle {
    font-size: 2rem;
    margin-bottom: 1.2rem;
    padding: 0.1rem;
  }
  
  .subscriptionDescription {
    margin-top: 1.5rem;
  }
}
