.magazineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 80vh;
  background: transparent;
  position: relative;
}

.controls {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -10px;
  padding: 10px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 2;
  justify-content: center;
}

.controlButton {
  font-size: 24px;
  padding: 8px 16px;
  border: none;
  background: var(--marketplaceColor);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.controlButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageInfo {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.documentWrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  height: auto;
  overflow: visible;
}

.document {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
}

.pagesContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  height: 100%;
}

.page {
  max-width: 45%;
  height: auto;
  object-fit: contain;
}

.singlePage {
  max-width: 70%;
  height: auto;
  object-fit: contain;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.progressBar {
  width: 200px;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  margin-top: 10px;
}

.progressFill {
  height: 100%;
  background: var(--marketplaceColor);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.mobileInstructions {
  display: none;
  text-align: center;
  color: #666;
  margin-top: 20px;
}

.error {
  color: red;
  text-align: center;
  padding: 20px;
  font-weight: 500;
}

.backButton {
  position: fixed;
  top: 115px;
  left: 40px;
  padding: 10px 20px;
  background: var(--marketplaceColor);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .magazineContainer {
    padding: 0;
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    min-height: 74vh;
  }

  .documentWrapper {
    flex: 0 auto;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .document {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .controls {
    position: relative;
    width: 60%;
    margin: 20px auto;
    justify-content: space-between;
    background: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 15px;
    bottom: auto;
    left: auto;
    transform: none;
  }

  .page, .singlePage {
    width: auto !important;
    height: auto !important;
    max-height: calc(100vh - 200px) !important;
    max-width: 95vw !important;
  }

  .backButton {
    position: fixed;
    top: 90px;
    left: 20px;
  }

  .mobileInstructions {
    text-align: center;
    margin: 10px 0;
    padding: 10px;
    background: rgba(255, 255, 255, 0.95);
  }
}

/* Ajustes específicos para pantallas muy pequeñas */
@media (max-width: 380px) {
  .controls {
    padding: 10px;
  }

  .controlButton {
    padding: 6px 12px;
    font-size: 20px;
  }

  .backButton {
    top: 90px;
    left: 20px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .pageInfo {
    font-size: 14px;
  }
} 