.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #1345CA;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.loadingSpinner {
  width: 100px;
  height: 100px;
  animation: bounce 2s ease infinite;
  border-radius: 15px;
}

.loadingText {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #F5F5F5;
  font-weight: 500;
  min-width: 150px;
  text-align: center;
}

.counterText {
  margin-top: 8px;
  font-size: 2rem;
  color: #F5F5F5;
  font-weight: bold;
  animation: pulse 1s infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
} 