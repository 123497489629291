.accordionContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .accordionTitle {
    font-size: 40px;
    margin-bottom: 15px;
    color: #333;
    text-align: center; /* Asegura que el título esté centrado */
  }
  
  .accordionDescription {
    margin-bottom: 25px;
    color: #666;
    text-align: center;
    font-weight: 500;
  }
  
  .accordionItem {
    margin-bottom: 15px;
  }
  
  .accordionButton {
    background-color: #ffffff;
    color: #1345ca; /* Cambiado al azul de Stuvi */
    cursor: pointer;
    padding: 15px 20px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .accordionButton:hover {
    background-color: #f8f8f8;
  }
  
  .accordionButton.active {
    background-color: #f0f0f0;
  }
  
  .accordionIcon {
    font-size: 24px;
    font-weight: bold;
    transition: transform 0.3s ease;
    color: #1652f0; /* Cambiado al azul de Stuvi */
  }
  
  .open {
    transform: rotate(180deg);
  }
  
  .accordionContent {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 0 0 8px 8px;
    margin-top: -8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    line-height: 1.6;
    color: #444;
  }
  
  @media (max-width: 767px) {
    .accordionContainer {
      padding: 15px;
    }
    
    .accordionButton {
      font-size: 16px;
      padding: 12px 15px;
    }
  
    .accordionTitle {
      font-size: 24px;
    }
  
    .accordionDescription {
      font-size: 14px;
    }
  }